export const isRegistrationDisabled = ({ selectedLevel, dancerType }) => {
  // const disabledFollower =
  //   ['level1', 'level2', 'level3', 'level4'].includes(selectedLevel) &&
  //   dancerType === 'follower';
  //
  // const disabledLead =
  //   ['level1', 'level2', 'level3', 'level4'].includes(selectedLevel) &&
  //   dancerType === 'lead';
  //
  // return disabledFollower || disabledLead;
  return false;
};

export const getWarningMessage = () => {
  return `Fullsatt. Plats i väntelistan får man om man skicka in ett mail.`;
};
