import React from 'react';
import { Heading, VStack, Text } from '@chakra-ui/react';
import { Times } from './Times';
import { PERIOD } from 'config';

function Info() {
  const periodLabel = `${PERIOD.substring(0, 2)} ${PERIOD.substring(2)}`;
  return (
    <VStack align="stretch" justifyContent={'flex-start'}>
      <Heading size="lg" marginBottom={3}>
        {periodLabel}
      </Heading>
      {/*<Text background={'green.100'} marginRight={2} p={2} rounded={5}>*/}
      {/*  Webbsidan kommer att vara öppen för intresseanmälningar till nästa*/}
      {/*  termin från och med tisdag 10/12.*/}
      {/*</Text>*/}
      <Text>
        Är du intresserad av att komma i kontakt med Salsagiro, skicka ett
        e-mail till adressen nedan.
      </Text>
      <Times />
      <Heading size="lg" as="h3">
        Info
      </Heading>
      <Text>
        Salsa på Mäster Olofsgården (kubansk) fungerar i stort sett ideellt och
        därför är kostnaden ganska låg och tas fram genom att omkostnader delas
        lika mellan deltagare och är beroende av hur många deltagare det blir.
        Du får mer information då din intresseanmälan har kommit in.
      </Text>
    </VStack>
  );
}

export { Info };
